<template>
  <a-upload
    class="iss-upload"
    :list-type="listType"
    v-bind="$attr"
    :show-upload-list="false"
    :action="action"
    :headers="headers"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
    <template v-if="listType === 'text'">
      <a-button>
        <template #icon>
          <loading-outlined v-if="loading" />
          <upload-outlined v-else />
        </template>
        上传
      </a-button>
    </template>
    <template v-else>
      <img v-if="value" :src="value" />
      <div v-else>
        <loading-outlined v-if="loading" />
        <plus-outlined v-else />
        <div class="ant-upload-text">上传</div>
      </div>
    </template>
  </a-upload>
</template>

<script>
import { Upload } from 'ant-design-vue';
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    AUpload: Upload,
    LoadingOutlined,
    PlusOutlined,
    UploadOutlined,
  },
  props: {
    value: String,
    listType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    const { token, tenant } = this.$store.state.account;
    return {
      loading: false,
      action: `${process.env.VUE_APP_API_SERVER}/api/file/attachment/upload?bizType=GOODS_IMAGE&isSingle=true`,
      headers: { token, tenant },
    };
  },
  methods: {
    beforeUpload(file) {
      const isJpgOrPng = ['image/jpeg', 'image/png'].includes(file.type);
      if (!isJpgOrPng) {
        this.$message.error('只能上传 JPG/PNG 格式的图片!');
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 1MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    handleChange({ file }) {
      switch (file.status) {
        case 'uploading':
          this.loading = true;
          break;
        case 'done':
          this.loading = false;
          if (file.response.isSuccess) {
            this.$message.success('图片上传成功！');
            this.$emit('update:value', file.response.data.url);
          } else {
            this.$message.error(file.response.msg);
          }
          break;
        case 'error':
          this.loading = false;
          this.$message.error('图片上传失败！');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.iss-upload.ant-upload-picture-card-wrapper {
  display: block;
  :deep(.ant-upload.ant-upload-select-picture-card) {
    margin-bottom: 1px;
    img {
      width: 100%;
    }
    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
.has-error .iss-upload :deep(.ant-upload.ant-upload-select-picture-card) {
  border-color: @error-color;
}
</style>
