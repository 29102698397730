'use strict';

import http from '@/utils/http';

export default {
  getList(code, params) {
    return http.get('/issmart-campaign/campaign/page', { params, code });
  },
  getItemById(code, id) {
    return http.get(`/issmart-campaign/campaignData/${id}`, { code });
  },
  add(code, data) {
    return http.put('/issmart-campaign/campaignData', data, { code });
  },
  update(code, data) {
    return http.put('/issmart-campaign/campaign', data, { code });
  },
  updateApp(code, data) {
    return http.put(
      '/issmart-campaign/campaignAppRelation/updateByCampaignId',
      data,
      { code }
    );
  },
  delete(code, params) {
    return http.delete('/issmart-campaign/campaign', { params, code });
  },
  getGroupList(code) {
    return http.get('/issmart-campaign/campaignGroup/page', { code });
  },
  addGroup(code, data) {
    return http.post('/issmart-campaign/campaignGroup', data, { code });
  },
  deleteGroup(code, params) {
    return http.delete('/issmart-campaign/campaignGroup', { params, code });
  },
  getTemplateList(code) {
    return http.get('/issmart-campaign/campaignData/templateListPage', {
      code,
    });
  },
};
