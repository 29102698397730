<template>
  <div class="steps-wrapper">
    <div class="steps-title">
      <div class="title-item active">选择场景工具</div>
      <div :class="{ 'title-item': true, active: activeTab === 'two' }">
        配置信息
      </div>
    </div>
    <div class="steps-content">
      <div v-show="activeTab === 'one'">
        <a-row type="flex">
          <a-col flex="280px">
            <ul class="tmpl-list">
              <li
                v-for="item of templateList"
                :class="{ 'tmpl-item': true, active: type[1] === item.tempId }"
                :key="item.tempId"
              >
                <img
                  :src="require(`@/assets/images/activity/${item.tempId}.png`)"
                />
                <span>{{ item.tempName }}</span>
              </li>
            </ul>
          </a-col>
          <a-col flex="1">
            <div class="app-list">
              <template v-for="(item, index) of appList" :key="index">
                <div class="app-title">{{ item?.key }}</div>
                <div class="app-wrapper">
                  <div
                    v-for="app in item?.datas"
                    :class="{
                      'app-item': true,
                      active: selectedApps.includes(
                        `${app.stepName}-${app.appClientId}`
                      ),
                    }"
                    :key="app.appClientId"
                    @click="handleClickByApp(app)"
                  >
                    <component class="iconfont" :is="icons[app.icon]" />
                    {{ app.appClientName }}
                  </div>
                </div>
              </template>
            </div>
          </a-col>
        </a-row>
        <div class="mt-10 ta-right">
          <a-button type="primary" @click="activeTab = 'two'">下一步</a-button>
        </div>
      </div>
      <div v-show="activeTab === 'two'">
        <a-form
          class="iss-form"
          ref="formRef"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :validate-trigger="['change', 'blur']"
        >
          <a-form-item label="名称" name="campaignName">
            <a-input
              v-model:value="form.campaignName"
              autocomplete="off"
              placeholder="请输入名称"
            />
          </a-form-item>
          <a-form-item label="日期" name="date">
            <a-range-picker
              v-model:value="form.date"
              show-time
              style="width: 100%"
            />
          </a-form-item>
          <!-- <a-form-item label="时区" name="timeZone">
            <a-select
              v-model:value="form.timeZone"
              show-search
              placeholder="请选择时区"
              @change="(v, { item }) => (form.timeZoneCal = item.value)"
            >
              <a-select-option
                v-for="item in timeZoneList"
                :key="item.label"
                :value="item.label"
                :item="item"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="本地语言" name="localLanguage">
            <a-select
              v-model:value="form.localLanguage"
              placeholder="请选择本地语言"
            >
              <a-select-option
                v-for="item in languageList"
                :key="item.id"
                :value="item.itemNameEn"
              >
                {{
                  $store.state.account.language === 'zh-CN'
                    ? item.itemNameCn
                    : item.itemNameEn
                }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
          <a-form-item
            label="Banner图"
            name="banner"
            extra="建议上传宽高比为16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片"
          >
            <iss-image-upload
              v-model:value="form.banner"
              list-type="picture-card"
              accept=".jpe,.jpeg,.jpg,.png"
            />
          </a-form-item>
          <a-form-item label="国家/省市" style="margin-bottom: 0">
            <a-row :gutter="10">
              <a-col :span="8">
                <a-form-item name="country" :wrapper-col="{ span: 24 }">
                  <a-select
                    v-model:value="form.country"
                    show-search
                    placeholder="请选择国家"
                    disabled
                    :filter-option="filterOption"
                    @change="handleChangeByCountry"
                  >
                    <a-select-option
                      v-for="item in countryList"
                      :key="item.id"
                      :value="item.itemNameCn"
                      :item="item"
                    >
                      {{
                        $store.state.account.language === 'zh-CN'
                          ? item.itemNameCn
                          : item.itemNameEn
                      }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8" v-show="form.country === '中国'">
                <a-form-item name="province" :wrapper-col="{ span: 24 }">
                  <a-select
                    v-model:value="form.province"
                    allow-clear
                    show-search
                    placeholder="请选择省份"
                    @change="handleChangeByProvince"
                  >
                    <a-select-option
                      v-for="item in provinceList"
                      :key="item.id"
                      :value="item.itemCode"
                      :item="item"
                    >
                      {{
                        $store.state.account.language === 'zh-CN'
                          ? item.itemNameCn
                          : item.itemNameEn
                      }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8" v-show="form.country === '中国'">
                <a-form-item name="city" :wrapper-col="{ span: 24 }">
                  <a-select
                    v-model:value="form.city"
                    allow-clear
                    show-search
                    placeholder="请选择城市"
                  >
                    <a-select-option
                      v-for="item in cityList"
                      :key="item.id"
                      :value="item.itemCode"
                    >
                      {{
                        $store.state.account.language === 'zh-CN'
                          ? item.itemNameCn
                          : item.itemNameEn
                      }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="地址" name="location">
            <a-input
              v-model:value="form.location"
              autocomplete="off"
              placeholder="请输入地址"
            />
          </a-form-item>
          <a-form-item label="人数" name="campaignScale">
            <a-input-number v-model:value="form.campaignScale" :min="0" />
          </a-form-item>
          <a-form-item label="描述" name="campaignDesc">
            <a-textarea
              v-model:value="form.campaignDesc"
              showCount
              :maxlength="100"
            />
          </a-form-item>
        </a-form>
        <div class="mt-10 ta-right">
          <a-space>
            <a-button @click="activeTab = 'one'">上一步</a-button>
            <a-button @click="$refs.formRef.resetFields()">重置</a-button>
            <a-button type="primary" @click="handleClickBySubmit">
              保存
            </a-button>
          </a-space>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  TreeSelect,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IssImageUpload from '@/components/imageUpload';
import timeZoneList from '@/dictionaries/timeZone.json';
import activityApi from '@/api/activity';
import dictionaryApi from '@/api/dictionary';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AInputNumber: InputNumber,
    ATreeSelect: TreeSelect,
    ASpace: Space,
    IssImageUpload,
  },
  data() {
    const type = this.$route.params.type;
    const typeList = {
      host: '101',
      exhibition: '102',
      live: '103',
      thematic: '104',
      socialmedia: '105',
    };
    const required = { required: true, message: '不能为空' };

    return {
      icons,
      timeZoneList,
      type: [type, typeList[type]],
      activeTab: 'one',
      templateList: [],
      appList: [],
      selectedApps: [],
      languageList: [],
      countryList: [],
      provinceList: [],
      cityList: [],
      loading: false,
      rules: {
        campaignName: required,
        localLanguage: required,
        timeZone: required,
        date: required,
        banner: required,
        country: required,
      },
      form: {
        tempId: typeList[type],
        campaignName: '',
        campaignDesc: '',
        localLanguage: 'Chinese',
        timeZone: '(GMT+08:00) China',
        timeZoneCal: '+08:00',
        date: null,
        campaignScale: 0,
        banner: '',
        location: '',
        country: '中国',
        province: null,
        city: null,
        tempApp: [],
      },
    };
  },
  created() {
    this.getTemplateList();
    this.getDictionary();
  },
  methods: {
    getTemplateList() {
      activityApi.getTemplateList(`${this.type[0]}:add`).then(data => {
        const { tempApp } = data.find(i => i.tempId === this.type[1]);
        const appList = [];
        const selectedApps = [];
        Object.keys(tempApp).forEach(key => {
          if (key.indexOf('前') > -1 || ['制作'].includes(key)) {
            appList[0] = { key, datas: tempApp[key] };
          } else if (key.indexOf('中') > -1 || ['引流'].includes(key)) {
            appList[1] = { key, datas: tempApp[key] };
          } else if (key.indexOf('后') > -1 || ['转化'].includes(key)) {
            appList[2] = { key, datas: tempApp[key] };
          } else {
            appList.push({ key, datas: tempApp[key] });
          }
          selectedApps.push(
            tempApp[key].map(i => `${i.stepName}-${i.appClientId}`)
          );
        });
        this.selectedApps = selectedApps.flat();
        this.templateList = data;
        this.appList = appList;
      });
    },
    getDictionary() {
      dictionaryApi
        .getItemList(`${this.type[0]}:add`, { code: 'LOCAL_LANGUAGE' })
        .then(data => (this.languageList = data));
      dictionaryApi
        .getItemTree(`${this.type[0]}:add`, { code: 'AREA' })
        .then(data => (this.countryList = data));
      dictionaryApi
        .getItemTree(`${this.type[0]}:add`, { code: 'STATE' })
        .then(data => (this.provinceList = data));
    },
    filterOption(input, { item }) {
      return item.itemNameCn.indexOf(input) >= 0;
    },
    handleChangeByCountry() {
      this.form.province = null;
      this.form.city = null;
    },
    handleChangeByProvince(value, option) {
      this.cityList = option ? option.item.children : [];
      this.form.city = null;
    },
    handleClickByApp(item) {
      const key = `${item.stepName}-${item.appClientId}`;
      const index = this.selectedApps.findIndex(i => i === key);
      if (index > -1) {
        this.selectedApps.splice(index, 1);
      } else {
        this.selectedApps.push(key);
      }
    },
    handleClickBySubmit() {
      this.$refs.formRef.validate().then(() => {
        const date = this.form.date;
        this.selectedApps.forEach(item => {
          const arr = item.split('-');
          this.appList
            .find(i => i.key === arr[0])
            .datas.forEach(i => {
              i.appClientId === arr[1] &&
                this.form.tempApp.push({ ...i, isConfig: 'true' });
            });
        });
        this.form.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
        this.form.startTimestamp = date[0].toDate().getTime();
        this.form.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');
        this.form.endTimestamp = date[1].toDate().getTime();
        activityApi.add(`${this.type[0]}:add`, this.form).then(() => {
          const path = this.$route.path;
          this.$router.push(path.substring(0, path.length - 4));
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.steps-title {
  display: flex;
  width: calc(100% - 11px);
  .title-item {
    flex: 1;
    position: relative;
    margin-right: 10px;
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    text-align: center;
    border-top: 1px solid #eee;
    box-shadow: 2px 2px 4px #d9d9d9;
    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: -15px;
      z-index: 2;
      border: 15px solid transparent;
      border-color: #fff #fff transparent transparent;
      transform: rotate(45deg);
      box-shadow: 2px -2px 4px #d9d9d9;
    }
    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: -15px;
      z-index: 3;
      border: 15px solid transparent;
      border-color: #fff #fff transparent transparent;
      transform: rotate(45deg);
      box-shadow: 2px -2px 4px #d9d9d9;
    }
    &.active {
      background-color: @primary-color;
      color: #fff;
      &::after {
        border-color: @primary-color @primary-color transparent transparent;
        box-shadow: none;
      }
    }
  }
}
.steps-content {
  margin-top: 10px;
  padding: 10px 10px 0 10px;
}
.tmpl-list {
  margin-right: 20px;
  .tmpl-item {
    margin-bottom: 15px;
    padding: 15px 25px;
    background-color: #f5f5f5;
    img {
      margin-right: 15px;
      width: 32px;
      height: 32px;
    }
    &.active {
      background-color: #fff;
      color: @primary-color;
      border: 1px solid @primary-color;
      box-shadow: 0 2px 10px 0 #d9d9d9;
      border-radius: 10px;
    }
  }
}
.app-list {
  padding: 0 20px 20px 20px;
  border: 1px solid @border-color-base;
  height: calc(100vh - 212px);
  overflow-y: auto;
  .app-title {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0 10px 0;
  }
  .app-wrapper {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    .app-item {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: @blue-1;
      border: 1px dashed @blue-5;
      border-radius: 10px;
      opacity: 0.6;
      cursor: pointer;
      .iconfont {
        padding: 5px;
        margin-right: 10px;
        height: 56px;
        width: 56px;
        line-height: 56px;
        text-align: center;
        font-size: 40px;
        color: #fff;
        border-radius: 8px;
        background-color: @blue-5;
      }
      &.active {
        border-style: solid;
        box-shadow: 0 4px 10px @blue-2;
        opacity: 1;
      }
    }
    &:nth-child(4) {
      .app-item {
        background-color: @green-1;
        border-color: @green-5;
        .iconfont {
          background-color: @green-5;
        }
        &.active {
          box-shadow: 0 4px 10px @green-2;
        }
      }
    }
    &:nth-child(6) {
      .app-item {
        background-color: @purple-1;
        border-color: @purple-5;
        .iconfont {
          background-color: @purple-5;
        }
        &.active {
          box-shadow: 0 4px 10px @purple-2;
        }
      }
    }
  }
}
.iss-form {
  height: calc(100vh - 212px);
  overflow-y: auto;
}
</style>
